<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>预约信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="预约人">
                <div style="width: 500px;">
                  {{ formData.userName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="预约人电话">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="预约时间">
                <div style="width: 500px;">
                  {{ formData.reservationDate }} {{ formData.timeSlot }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="地址">
                <div style="width: 500px;">
                  {{ formData.address }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="预约业务">
                <div style="width: 500px;">
                  {{ formData.workName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注">
                <div style="width: 500px;">
                  {{ formData.remarks }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                <div style="width: 500px;">
                  <a-tag color="#f50" v-if="formData.status === 0">未处理</a-tag>
                  <a-tag color="#87d068" v-else-if="formData.status === 1">已处理</a-tag>
                  <a-tag color="#2db7f5" v-else-if="formData.status === 2">驳回预约</a-tag>
                  <a-tag color="#595959" v-else>已取消</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="业务办理情况">
                <div style="width: 500px;">
                  {{ formData.dealRemarks }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="办理业务时间">
                <div style="width: 500px;">
                  {{ formData.dealTime }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="创建时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { workRecordInfo } from '@/api/work'
export default {
  name: 'SeeRecord',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {}
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    recordInfoApi (keyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      workRecordInfo({ keyId: keyId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
